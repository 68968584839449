

@import '@fluentui/react/dist/sass/References';

$border-radius: 0.3rem;
$box-shadow: 0.5rem;

:root {
  --bs-border-radius: .25rem;;
}


.ms-Button,
.ms-Callout,
.ms-Callout-main,
.ms-Stack,
.ms-TextField-fieldGroup,
input[type=text],
input[type=password]{
  border-radius: var(--bs-border-radius);
}

.ms-TextField-fieldGroup{
  border: 1px solid var(--bs-gray-300);
  transition : border 250ms ease-out;
  &:hover, &:focus{
    border-color: var(--bs-primary);
  }
}



.main-content-wrapper{
  border-radius:$border-radius;
}

.ms-Nav{
  .ms-Nav-group{
    .ms-Nav-groupContent{
      margin-bottom: 1rem;
    }
    h3{
      font-size: $ms-font-size-20;
      font-weight: 600;
    }
    a{
      font-weight: 400;
      i{
        margin: 0 1rem 0 1rem;
        font-size: 1.2rem;
      }
    }
  }
}


.menu-bar{
  height: 1.5rem !important;
}


.app-title{
  span{
    font-family: Colus, Arial, serif;
    font-weight: bold;
    font-size: $ms-font-size-28;
  }
  & :last-child{
    color: $ms-color-sharedCyanBlue10 ;
  }
}


.loader-wrapper{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1301;
  width: 100%
}


@media (max-width: 767px)
{
  .bg-home {
    padding: 150px 0;
    height: 100vh;
  }
}
